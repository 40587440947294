$(function() {
  $('.js-scroll-to').click(function (e) {
    e.preventDefault();

    var page = $(this).data('target'),
        speed = 750,
        menuHeight,
        windowWidth;

    function getWindowWith() {
      windowWidth = $(window).width();
    }

    getWindowWith();

    $(window).resize(function () {
      getWindowWith();
    });

    menuHeight = parseInt($page.css('scroll-margin-top'));
    // if (windowWidth >= 780) {
    //   menuHeight = 60;
    // }
    // else {
    //   menuHeight = 60;
    // }

    if ($('#wpadminbar').length > 0) {
      menuHeight += 50;
    }

    var $page = $(page);

    var scrollTop = $page.offset().top;
    scrollTop -= menuHeight;

    $('html, body').animate({
      scrollTop: scrollTop
    }, speed);

    return false;
  });
});
